<template>
  <div>
    <Header />
    <Offers />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Offers from "@/components/Homepage/Offers.vue";
export default {
  name: "Mint",
  data() {
    return {
    };
  },
  components: {
    Header,
    Offers,
    Footer,
  },
 
};
</script>

<style></style>
