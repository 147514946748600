<template>
  <div>
    <Header />
    <div style="height: 50vh; position: relative">
      <MintButton :list ="$route.params.list"/>
    </div>
    <Footer />
  </div>
</template>

<script>
import MintButton from "@/components/MintButton.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Offers from "@/components/Homepage/Offers.vue";
export default {
  name: "Mint",
  data() {
    return {};
  },
  components: {
    Header,
    Offers,
    Footer,
    MintButton,
  },
};
</script>

<style></style>
